import type { RcFile } from "antd/es/upload/interface";

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const getYouTubeID = (url: string) => {
  const arr = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return undefined !== arr[2] ? arr[2].split(/[^\w-]/i)[0] : arr[0];
};

export const getEnums = (key:string) => {
  let enumsStorage:any = localStorage.getItem("SPORT_CENTER_APP.enums");
  return key ? JSON.parse(enumsStorage)[key] : JSON.parse(enumsStorage)
}