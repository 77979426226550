import { AuthPage } from "auth";
import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "Store/features/authSlice";
import PersistLogin from "auth/components/PersistLogin";

const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<PersistLogin />}>
          <Route path="error/*" element={<div>ErrorsPage</div>} />
          <Route path="logout" element={<div>Logout</div>} />
          {isAuthenticated ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to="/dashboard" />} />
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
