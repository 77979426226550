import React from "react";

export interface ComponentProps {
  name?: string;
  formSize?: string;
  type?: string;
  className?: string;
  placeholder?: string;
  defaultValue?: any;
  value?: any;
  onChange?: any;
  props?: any;
}

export const SCInput = React.forwardRef(
  (
    {
      name,
      formSize = "lg",
      type = "text",
      className,
      placeholder,
      defaultValue,
      value,
      onChange,
      ...props
    }: any,
    ref: any
  ) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event);
    };
    return (
      <input
        ref={ref}
        type={type}
        name={name}
        className={`form-control form-control-${formSize} form-control-solid mb-3 mb-lg-0 ${
          className || ""
        }`}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange}
        {...props}
      />
    );
  }
);
