import App from "App";
import { useEffect, useState, useCallback } from "react";
import { useTokenMutation } from "auth/AuthApi";
import { LayoutSplashScreen } from "Layout/core";
import { TokenHelper } from "Helpers/Token.helper";
import {
  useGetUserRoleMutation,
  useGetUserSportCenterMutation,
} from "Layout/CommonApi";

const PersistLogin: any = () => {
  const [loading, setLoading] = useState(true);
  const [tokenApi] = useTokenMutation();
  const [userSportCenterApi] = useGetUserSportCenterMutation();
  const [userRoleApi] = useGetUserRoleMutation();

  const fetchData = useCallback(async () => {
    let hasToken = TokenHelper.Check();
    if (hasToken) {
      let accessToken = TokenHelper.Get.AccessToken();
      await tokenApi(accessToken);
      let { data: userSportCenter }: any = await userSportCenterApi({});
      await userRoleApi({ id: userSportCenter.data[0].roleId });

      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData().catch(console.error);
  }, [fetchData]);

  let content;
  if (!loading) {
    content = <App />;
  } else {
    content = <LayoutSplashScreen />;
  }

  return content;
};
export default PersistLogin;
