import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { store } from "Store/store";
import { AppRoutes } from "Routes/AppRoutes";

import "Assets/sass/plugins.scss";
import "Assets/sass/style.scss";
import "Assets/sass/style.react.scss";
import "react-toastify/dist/ReactToastify.css";
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import { ToastContainer } from 'react-toastify';
import { ThemeModeProvider } from "Layout/core/theme-mode/ThemeModeProvider";
import { MetronicI18nProvider } from "Helpers/i18n/Metronici18n";

const container = document.getElementById("root")!;
const root = createRoot(container);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <MetronicI18nProvider>
        <ThemeModeProvider>
          <AppRoutes />
          <ToastContainer />
        </ThemeModeProvider>
      </MetronicI18nProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </Provider>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
