export const ApiURLS = {
  USER: "user",
  USER_SPORT_CENTER: "user-sport-center",
  MEMBER: "member",
  PENDING_MEMBER: "user-sport-center/pending-user",
  EQUIPMENT: "gym-equipment",
  EXERCISE: "exercise",
  WORKOUT: "workout",
  WORKOUT_SPORT_CENTER: "workout-sport-center",
  WORKOUT_USER: "workout-user",
  ENUMS: "enum",
  PERMISSION: "permission",
  ROLE: "role",
  MENU: "menu",
  ADDRESS: "address",
  LOGIN: "auth/login",
  REFRESH_TOKEN: "auth/refresh",
  LOGOUT: "auth/logout",
  TOKEN : "auth/token"
}

export const PageURLS = {
  EQUIPMENT: "equipments",
  EXERCISE: "exercises",
  WORKOUT: "workouts",
  MEMBER: "members",
  USER: "users",
  ROLES: "roles"
}

export const CdnURL = "https://ucarecdn.com"