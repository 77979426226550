// import { TokenHelper } from "Helpers/Token.helper";
import axios from "axios";
import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { TokenHelper } from "Helpers/Token.helper";
import { RootState } from "Store/store";
import {  setLogin } from "Store/features/authSlice";
import { ApiURLS } from "Constants/RequestURL";
// import { RootState } from "Store/store";

export const ApiSportCenter = axios.create();

const API_URL = `${process.env.REACT_APP_API_URL}`;
// const API_URL = "https://sportcenter.onrender.com/api/v1";
// const API_URL = "http://localhost:5001/api/v1";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    const sportCenterId = "6400c2e7b5272352102b74d4";
    // const sportCenterId = "63cedfaecf42d47c44781d7b";
    if (token) {
      headers.set("authentication", `Bearer ${token}`);
    }
    headers.set("sport_center_id", `${sportCenterId}`);
    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401) {
    const refreshToken = TokenHelper.Get.RefreshToken();
    const refreshResult: any = await baseQuery(
      {
        url: "/auth/refresh",
        body: {
          refreshToken,
        },
        method: "POST",
      },
      api,
      extraOptions
    );
    if (refreshResult?.data) {
      api.dispatch(setLogin(refreshResult.data.data));
      if (args.url === ApiURLS.TOKEN) {
        args.body = {
          authorization: refreshResult.data?.data?.accessToken,
        };
      }
      result = await baseQuery(args, api, extraOptions);
    } else {
      if (refreshResult?.error?.status === 401) {
        refreshResult.error.data.message = "Your login has expired";
      }
      return refreshResult;
    }
  }
  return result;
};

export const SportCenterApi = createApi({
  tagTypes: [
    "User",
    "Role",
    "Exercise",
    "WorkoutSportCenter",
    "WorkoutUser",
    "Workout",
    "Equipment",
    "Member",
    "Address",
    "Token",
    "UserSportCenter",
  ],
  // reducerPath: 'splitApi',
  baseQuery: baseQueryWithReauth,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  // tagTypes: ['Time', 'Posts', 'Counter'],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
});
