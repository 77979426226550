import { WithChildren } from "Helpers";
import { getCSSVariableValue } from "Layout/components/_utils";
import { DisableSidebar } from "Layout/core";
import { MasterLayout } from "Layout/MasterLayout";
import {
  FC,
  Fragment,
  Suspense,
  useEffect,
  useState,
  useCallback,
} from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import {
  // RoutesChildModel,
  RoutesModel,
  SiteRoutes,
} from "Constants/Routes";
import { useGetMenuMutation } from "Layout/MasterApi";
import { SCError } from "Components/SCError/SCError";
import { SCLoading } from "Components/SCLoading/SCLoading";
import { selectMenu } from "Store/features/authSlice";
import { useSelector } from "react-redux";

const PrivateRoutes = () => {
  const [menuApi] = useGetMenuMutation();
  const menu = useSelector(selectMenu);
  const [allowedPaths, setAllowedPaths] = useState<string[]>([]);
  const [test, setTest] = useState(false);

  const fetchData = useCallback(async () => {
    await menuApi({});
  }, []);

  useEffect(() => {
    fetchData().catch(console.error);
  }, [fetchData]);

  useEffect(() => {
    if (menu) {
      let allowed: string[] = [];
      menu.map((menu: any) => {
        if (menu?.subMenu?.length) {
          return menu?.subMenu.map(
            (subMenu: any) => subMenu.link && allowed.push(subMenu.link)
          );
        } else {
          return menu.link && allowed.push(menu.link);
        }
      });
      setAllowedPaths(allowed);
    }
  }, [menu]);

  if (!menu) {
    return <SCLoading />;
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/" />} />

        {SiteRoutes.map((siteRoute: RoutesModel, index: number) => {
          return (
            <Route
              key={index}
              path={siteRoute.path}
              element={
                <SuspensedView>
                  {allowedPaths.find((allowedPath) =>
                    siteRoute.path.includes(allowedPath)
                  ) ? (
                    siteRoute.components
                  ) : (
                    <SCError />
                  )}
                </SuspensedView>
              }
            />
          );
        })}
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  );
};

export { PrivateRoutes };
