import { SCButton } from "Components/SCButton";
import { SCInput } from "Components/SCInput";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useGetRoleQuery, useRoleFormMutation } from "./RolesApi";
import { useNavigate, useParams } from "react-router-dom";
import { SCLoading } from "Components/SCLoading/SCLoading";
import { SCError } from "Components/SCError/SCError";
import moment from "moment";
import { getEnums } from "Helpers/CommonHelper";
import { SCSelect } from "Components/SCSelect";
import { PageURLS } from "Constants/RequestURL";
import { toast } from "react-toastify";
import { RolePermissions } from "Constants/CommonModels";

const RoleForm = () => {
  const intl = useIntl();
  const { id } = useParams();
  const newForm = id && id === "new";
  const Permissions = getEnums("RolePermissions");
  const Pages = getEnums("Pages");
  const { handleSubmit, control, register } = useForm<any>();
  const [roleForm] = useRoleFormMutation();
  const navigate = useNavigate();
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const {
    data: role,
    isLoading,
    isError,
  } = useGetRoleQuery(id, { skip: !!newForm });

  const onSubmit = (updateData: any) => {
    let roleSettings: any = [];
    Object.entries(updateData).forEach((_data: any) => {
      const key = _data[0];
      const value = _data[1];
      if (!["roleName"].includes(key)) {
        roleSettings.push({
          permissions: value.key,
          routeId: key,
        });
      }
    });
    let dataModel: any = {
      roleName: updateData.roleName,
      roleSettings,
      ...(!newForm && { _id: id }),
    };

    setSubmitButtonLoading(true);
    roleForm({
      data: dataModel,
      method: newForm ? "POST" : "PUT",
      isNew: newForm,
    })
      .then(({ data: newData }: any) => {
        let newDataId = newData.data._id;
        navigate(`/${PageURLS.ROLES}/${newDataId}`);
        toast.success(
          intl.formatMessage({
            id: newForm
              ? "GENERAL.FORM_SAVE_SUCCESS"
              : "GENERAL.FORM_UPDATE_SUCCESS",
          })
        );
        setSubmitButtonLoading(false);
      })
      .catch(() => {
        toast.error(
          intl.formatMessage({
            id: newForm
              ? "GENERAL.FORM_SAVE_ERROR"
              : "GENERAL.FORM_UPDATE_ERROR",
          })
        );
        setSubmitButtonLoading(false);
      });
  };

  if (isLoading) {
    return <SCLoading />;
  }

  if (isError) {
    return <SCError />;
  }

  const detail = role?.data;
  let roleSettings;
  console.log(detail);
  if (!detail) {
    // roleSettings =
    let data: any = [];
    Pages.forEach((page: any) => {
      data.push({
        routeId: page.key,
        permissions: RolePermissions.ReadAndUpdate,
      });
    });
    roleSettings = data;
  } else {
    roleSettings = detail?.roleSettings;
  }
  let tempRoleSettings: any = [];
  console.log(roleSettings);
  roleSettings.forEach((setting: any) => {
    let _selectedRoleItem = Pages.find(
      (page: any) => page.key === setting.routeId
    );
    if (_selectedRoleItem) {
      tempRoleSettings.push({
        ...setting,
        key: `ENUM.PAGES.${_selectedRoleItem.value}`,
      });
    }
  });

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-header border-0 align-items-center row">
        <div className="col-lg-6">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">
              {intl.formatMessage({ id: !newForm ? "ROLE.EDIT" : "ROLE.ADD" })}
            </h3>
          </div>
        </div>
        <div className="col-lg-6">
          {!newForm && (
            <div className="col-lg-12 form_created_date">
              {intl.formatMessage({ id: "GENERAL.CREATED_DATE" })}:
              <span>
                {(detail?.registerDate &&
                  moment(detail?.registerDate).format("DD.MM.YYYY")) ||
                  "-"}
              </span>
            </div>
          )}
        </div>
      </div>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card-body border-top p-9">
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                {intl.formatMessage({ id: "ROLE.NAME" })}
              </label>

              <div className="col-lg-8">
                <SCInput
                  placeholder={intl.formatMessage({
                    id: "ROLE.NAME",
                  })}
                  defaultValue={detail?.roleName || ""}
                  {...register("roleName")}
                />
              </div>
            </div>
            {tempRoleSettings.map((item: any, index: number) => (
              <div className="row mb-6" key={index}>
                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  {intl.formatMessage({ id: item.key })}
                </label>

                <div className="col-lg-8 fv-row">
                  <SCSelect
                    control={control}
                    name={item.routeId}
                    options={Permissions}
                    placeholder={intl.formatMessage({
                      id: "EXERCISE.EQUIPMENTS",
                    })}
                    defaultValue={item.permissions}
                    labelIsResource={true}
                    optionPrefix="ENUM.ROLE_PERMISSIONS."
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <SCButton
              type="reset"
              className="btn-light btn-active-light-primary me-2"
              text={intl.formatMessage({ id: "GENERAL.DISCARD_CHANGES" })}
            />
            <SCButton
              type="submit"
              className="btn-primary"
              isLoading={submitButtonLoading}
              text={intl.formatMessage({ id: "GENERAL.SAVE_CHANGES" })}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
export default RoleForm;
