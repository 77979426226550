import { ApiURLS } from "Constants/RequestURL";
import { SportCenterApi } from "Services/ApiSportCenter";
import {
  authError,
  setUserMember,
  setUserRole,
  setUserSportCenter,
} from "Store/features/authSlice";

export const commonApi = SportCenterApi.injectEndpoints({
  endpoints: (build) => ({
    getCity: build.query<any, void>({
      query() {
        return { url: ApiURLS.ADDRESS, method: "GET" };
      },
      transformResponse: (results: any) => {
        let cities: any = [];
        results.data.forEach((city: string, index: number) => {
          cities.push({
            key: index + 1,
            value: city,
          });
        });
        // console.log("cities",cities)
        return cities;
      },
    }),
    getDistricts: build.query<any, any>({
      query({ cityId }: any) {
        return { url: `${ApiURLS.ADDRESS}/${cityId}`, method: "GET" };
      },
      transformResponse: (results: any) => {
        let districts: any = [];
        results.data.forEach((district: string, index: number) => {
          // console.log(district)
          districts.push({
            key: index + 1,
            value: district,
          });
        });
        // console.log("districts",districts)
        return districts;
      },
    }),
    getNeighbourhoods: build.query<any, any>({
      query({ cityId, districtId }: any) {
        return {
          url: `${ApiURLS.ADDRESS}/${cityId}/${districtId}`,
          method: "GET",
        };
      },
      transformResponse: (results: any) => {
        let neighbourhoods: any = [];
        results?.data?.forEach((neighbourhood: string, index: number) => {
          neighbourhoods.push({
            key: index + 1,
            value: neighbourhood,
          });
        });
        // console.log("neighbourhoods",neighbourhoods)
        return neighbourhoods;
      },
    }),
    getUserSportCenter: build.mutation({
      query: () => ({
        url: ApiURLS.USER_SPORT_CENTER,
        method: "GET",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { data },
          } = await queryFulfilled;
          dispatch(setUserSportCenter(data && data.length > 0 ? data[0] : []));
        } catch (error: any) {
          // dispatch(authError(error.error));
        }
      },
    }),
    getUserRole: build.mutation({
      query: ({ id }) => `${ApiURLS.ROLE}/${id}`,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { data },
          } = await queryFulfilled;
          dispatch(setUserRole(data.roleSettings));
        } catch (error: any) {
          // dispatch(authError(error.error));
        }
      },
    }),
  }),
});

export const {
  useGetCityQuery,
  useGetDistrictsQuery,
  useGetNeighbourhoodsQuery,
  useGetUserSportCenterMutation,
  useGetUserRoleMutation,
} = commonApi;
