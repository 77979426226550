import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { SportCenterApi } from 'Services/ApiSportCenter';
// import counterReducer from 'Store/features/counter/counterSlice';
import authReducer from 'Store/features/authSlice'

export const store = configureStore({
  reducer: {
    [SportCenterApi.reducerPath]: SportCenterApi.reducer,
    auth: authReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(SportCenterApi.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
