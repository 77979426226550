/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { toAbsoluteUrl } from "Helpers";
import { useDispatch } from "react-redux";
// import {
//   LoginRequest,
//   useGetTokenMutation,
//   useGetUserByTokenMutation,
//   // useLoginMutation,
// } from "auth/AuthApi";
import React from "react";
import { useLoginMutation, useTokenMutation } from "auth/AuthApi";
import { useGetMemberDetailQuery } from "Pages/Members/MembersApi";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

const initialValues = {
  email: "abdullah.aydin@gmail.com",
  password: "123456",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formState, setFormState] = React.useState<any>({
    email: "",
    password: "",
  });

  const [login] = useLoginMutation();
  const [token] = useTokenMutation();
  // const [member] = useGetMemberDetailQuery();
  // const [getUserByToken] = useGetUserByTokenMutation();

  const errorLogin = () => {
    // console.error(error);
    // saveAuth(undefined);
    // setStatus("The login detail is incorrect");
    // setSubmitting(false);
    setLoading(false);
  };
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      // setLoading(true);
      const {
        data: { data: tokenData },
      }: any = await login(values);
      // console.log(tokenData);
      const test1 = await token(tokenData?.accessToken);
      // login(values)
      //   .then(({ data }: any) => {
      //     console.log("token",data)
      //     // let { data : token } = data
      //     // saveAuth(token)
      //     // getUserByToken(token.accessToken)
      //     //   .then(({ data: user }: any) => {
      //     //     console.log("user",user);
      //     //     setCurrentUser(user);
      //     //   })
      //     //   .catch((error:any) => {
      //     //     console.error(error);
      //     //     errorLogin();
      //     //   });
      //   })
      //   .catch((error:any) => {
      //     console.error(error);
      //     errorLogin();
      //   });

      // try {

      //     /* tslint:disable:no-unused-variable */
      //   const {data: token} = await login(values);
      //   console.log(token)
      //   // if ("data" in data1) {
      //   //   saveAuth(data1.data);
      //   //   const { data: user } = await getUserByToken(auth.api_token);
      //   //   setCurrentUser(user);
      //   // }
      //   // console.log(data1.data)

      //   // const {data: user} = await getUserByToken(auth.api_token)
      //   // setCurrentUser(user)
      // } catch (error) {
      //   console.error(error);
      //   saveAuth(undefined);
      //   setStatus("The login detail is incorrect");
      //   setSubmitting(false);
      //   setLoading(false);
      // }
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">Sign In to Metronic</h1>
        <div className="text-gray-400 fw-bold fs-4">
          New Here?{" "}
          <Link to="/auth/registration" className="link-primary fw-bolder">
            Create an Account
          </Link>
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      ) : (
        <div className="mb-10 bg-light-info p-8 rounded">
          <div className="text-info">
            Use account <strong>admin@demo.com</strong> and password{" "}
            <strong>demo</strong> to continue.
          </div>
        </div>
      )}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bolder text-dark">Email</label>
        <input
          placeholder="Email"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            {/* begin::Label */}
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              Password
            </label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to="/auth/forgot-password"
              className="link-primary fs-6 fw-bolder"
              style={{ marginLeft: "5px" }}
            >
              Forgot Password ?
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <input
          type="password"
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Continue</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>

        {/* begin::Separator */}
        <div className="text-center text-muted text-uppercase fw-bolder mb-5">
          or
        </div>
        {/* end::Separator */}

        {/* begin::Google link */}
        <a
          href="#"
          className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
        >
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/svg/brand-logos/google-icon.svg")}
            className="h-20px me-3"
          />
          Continue with Google
        </a>
        {/* end::Google link */}

        {/* begin::Google link */}
        <a
          href="#"
          className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
        >
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/svg/brand-logos/facebook-4.svg")}
            className="h-20px me-3"
          />
          Continue with Facebook
        </a>
        {/* end::Google link */}

        {/* begin::Google link */}
        <a href="#" className="btn btn-flex flex-center btn-light btn-lg w-100">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/svg/brand-logos/apple-black.svg")}
            className="h-20px me-3"
          />
          Continue with Apple
        </a>
        {/* end::Google link */}
      </div>
      {/* end::Action */}
    </form>
  );
}
