import React from "react";

export interface ComponentProps {
  name?: string;
  type?: "button" | "submit" | "reset" | undefined;
  className?: string;
  onClick?: any;
  text: any;
  isLoading?: boolean;
  disabled?: boolean;
  props?: any;
  icon?: any;
}
export const SCButton = React.forwardRef(
  (
    {
      name,
      className = "btn-primary",
      onClick,
      text,
      type = "button",
      isLoading,
      disabled,
      icon,
      ...props
    }: ComponentProps,
    ref: any
  ) => {
    const handleClick = () => {
      onClick && onClick();
    };
    return (
      <button
        ref={ref}
        name={name}
        type={type}
        className={`btn  ${className}`}
        onClick={handleClick}
        disabled={disabled || isLoading}
        {...props}
      >
        {!isLoading ? (
          <div className="d-flex align-items-center">
            {icon}
            <span>{text}</span>
          </div>
        ) : (
          <span>
            Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        )}
      </button>
    );
  }
);
