/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { toAbsoluteUrl } from "Helpers";
import { UserMenu } from "./UserMenu";
import { KTSVG } from "Components/KTSVG";
import { useSelector } from "react-redux";
import { selectAuthenticatedUser } from "Store/features/authSlice";

const AsideUserMenu: FC = () => {
  const user = useSelector(selectAuthenticatedUser);
  
  return (
    <>
      <div className="d-flex flex-stack">
        <div className="d-flex align-items-center">
          <div className="symbol symbol-circle symbol-40px">
            <img src={toAbsoluteUrl("/media/avatars/300-1.jpg")} alt="avatar" />
          </div>
          <div className="ms-2">
            {/* <a
              href="#"
              className="text-gray-800 text-hover-primary fs-6 fw-bolder lh-1"
            ></a> */}
            <span className="text-muted fw-bold d-block fs-7 lh-1">
              {user?.name} {user?.surname}
            </span>
          </div>
        </div>
        <div className="ms-1">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary position-relative me-n2"
            data-kt-menu-trigger="click"
            data-kt-menu-overflow="false"
            data-kt-menu-placement="top-end"
          >
            <KTSVG
              path="/media/icons/duotune/coding/cod001.svg"
              className="svg-icon-1"
            />
          </div>
          <UserMenu />
        </div>
      </div>
    </>
  );
};

export { AsideUserMenu };
