export enum Gender {
  Male = 1,
  Female = 0,
}

export enum BloodGroup {
  ZeroNegative = 0,
  ANegative = 1,
  BNegative = 2,
  ABNegative = 3,
  ZeroPositive = 4,
  APositive = 5,
  BPositive = 6,
  ABPositive = 7,
}
export interface Address {
  city: string;
  district: string;
  neighbourhood: string;
  detail: string;
}
export enum RolePermissions {
  Unauthorized = "0",
  ReadOnly = "1",
  ReadAndUpdate = "2",
  FullAuthority = "3",
}