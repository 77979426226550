// import apiSlice from '../../app/api/apiSlice';
// import { logOut, setCredentials, authError } from './auth.slice';

import { ApiURLS } from "Constants/RequestURL";
import { ApiSportCenter, SportCenterApi } from "Services/ApiSportCenter";
import {
  authError,
  logOut,
  // setCredentials,
  setLogin,
  setUser,
} from "Store/features/authSlice";

export const authApiSlice = SportCenterApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: ApiURLS.LOGIN,
        method: "POST",
        body: credentials,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setLogin(data?.data));
        } catch (error: any) {
          dispatch(authError(error.error));
        }
      },
    }),
    token: builder.mutation({
      query: (credentials) => ({
        url: ApiURLS.TOKEN,
        method: "POST",
        body: {
          authorization: credentials,
        },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data?.data));
        } catch (error: any) {
          dispatch(authError(error.error));
        }
      },
    }),
    register: builder.mutation({
      query: (credentials) => ({
        url: "/auth/register",
        method: "POST",
        body: { ...credentials },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          dispatch(authError(error.error));
        }
      },
    }),
    googleAuth: builder.mutation({
      query: (token) => ({
        url: "/auth/google",
        method: "POST",
        body: { accessToken: token.access_token },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // dispatch(setCredentials(data));
        } catch (error: any) {
          dispatch(authError(error.error));
        }
      },
    }),
    logout: builder.mutation({
      query: () => ({
        url: ApiURLS.LOGOUT,
        method: "POST",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(logOut());
          let _ApiSportCenter: any = ApiSportCenter;
          console.log(_ApiSportCenter?.util)
          dispatch(_ApiSportCenter?.util?.resetApiState());
        } catch (error: any) {
          dispatch(authError(error.error));
        }
      },
    }),
    refresh: builder.mutation({
      query: (credentials) => ({
        url: ApiURLS.REFRESH_TOKEN,
        method: "POST",
        body: {
          refreshToken: credentials,
        },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // dispatch(setCredentials(data));
        } catch (error) {
          // dispatch(authError(error.error));
        }
      },
    }),
    requestResetPassword: builder.mutation({
      query: (email) => ({
        url: "/auth/password-reset",
        method: "POST",
        body: { email },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          dispatch(authError(error.error));
        }
      },
    }),
    resetPassword: builder.mutation({
      query: ({ passwordState, token }) => ({
        url: `/auth/password-reset/${token}`,
        method: "POST",
        body: { ...passwordState },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          dispatch(authError(error.error));
        }
      },
    }),
    requestEmailConfirmation: builder.mutation({
      query: (email) => ({
        url: "/auth/confirm-email",
        method: "POST",
        body: { email },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          dispatch(authError(error.error));
        }
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useTokenMutation,
  useGoogleAuthMutation,
  useLogoutMutation,
  useRefreshMutation,
  useRegisterMutation,
  useRequestResetPasswordMutation,
  useResetPasswordMutation,
  useRequestEmailConfirmationMutation,
  endpoints,
} = authApiSlice;
