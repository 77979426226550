import { ApiURLS } from "Constants/RequestURL";
import { SportCenterApi } from "Services/ApiSportCenter";

export const rolesApi = SportCenterApi.injectEndpoints({
  endpoints: (build) => ({
    roleList: build.query<any, void>({
      query() {
        return {
          url: ApiURLS.ROLE,
          method: "GET",
        };
      },
      providesTags: ({ data }) =>
        data
          ? [
              ...data.map(({ id }: any) => ({
                type: "Role" as const,
                id,
              })),
              { type: "Role", id: "LIST" },
            ]
          : 
          [{ type: "Role", id: "LIST" }],
      // transformResponse: (results: { data: { posts: any[] } }) =>
      //   results.data.posts,
    }),
    roleListForSelect: build.query<any, void>({
      query() {
        return {
          url: `${ApiURLS.ROLE}?select=true`,
          method: "GET",
        };
      },
      providesTags: ({ data }) =>
        data
          ? [
              ...data.map(({ id }: any) => ({
                type: "Role" as const,
                id,
              })),
              { type: "Role", id: "ROLE-SELECT-LIST" },
            ]
          : 
          [{ type: "Role", id: "ROLE-SELECT-LIST" }],
      // transformResponse: (results: { data: { posts: any[] } }) =>
      //   results.data.posts,
    }),
    roleListByUser: build.query<any, any>({
      query(id) {
        return {
          url: `${ApiURLS.ROLE}/${id}/users`,
          method: "GET",
        };
      },
      providesTags: ({ data }) =>
        data
          ? [
              ...data.map(({ id }: any) => ({
                type: "Role" as const,
                id,
              })),
              { type: "Role", id: "LIST-USER" },
            ]
          : [{ type: "Role", id: "LIST-USER" }],
    }),
    getRole: build.query<any, any>({
      query: (id) => `${ApiURLS.ROLE}/${id}`,
      providesTags: (_role, _err, id): any => [{ type: "Role", id }],
    }),
    roleForm: build.mutation<any, any>({
      query({data, method, isNew}) {
        return {
          url: `${ApiURLS.ROLE}${!isNew ? `/${data._id}` : '' }`,
          method: method,
          body: data,
        };
      },
      invalidatesTags: (role) => [
        { type: "Role", id: role?.id },
        { type: "Role", id: "LIST" },
      ],
    }),
    addRole: build.mutation<any, any>({
      query(data) {
        return {
          url: `${ApiURLS.ROLE}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (role) => [
        { type: "Role", id: role?.id },
        { type: "Role", id: "LIST" },
      ],
    }),
    updateRole: build.mutation<any, any>({
      query(data) {
        const { _id } = data;
        return {
          url: `${ApiURLS.ROLE}/${_id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (role) => [
        { type: "Role", id: role?.id },
        { type: "Role", id: "LIST" },
      ],
    }),
    deleteRole: build.mutation<any, any>({
      query(data) {
        return {
          url: `${ApiURLS.ROLE}`,
          method: "DELETE",
          body: { ids: data },
        };
      },
      invalidatesTags: (role) => [
        { type: "Role", id: role?.id },
        { type: "Role", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useRoleListQuery,
  useRoleListForSelectQuery,
  useGetRoleQuery,
  useAddRoleMutation,
  useUpdateRoleMutation,
  useRoleListByUserQuery,
  useDeleteRoleMutation,
  useRoleFormMutation
} = rolesApi;
