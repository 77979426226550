import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";

export interface ComponentProps {
  name?: any;
  type?: string;
  options?: any;
  className?: string;
  placeholder?: string;
  defaultValue?: any;
  selectedValue?: any;
  onChange?: any;
  props?: any;
  register?: any;
  control?: any;
  isMulti?: boolean;
  labelIsResource?: boolean;
  optionPrefix?: string;
}

export const SCSelect = React.forwardRef(
  (
    {
      name,
      options,
      className,
      placeholder,
      defaultValue,
      selectedValue,
      onChange,
      register,
      control,
      isMulti = false,
      labelIsResource = false,
      optionPrefix = "",
      ...props
    }: ComponentProps,
    ref: any
  ) => {
    const intl = useIntl();
    // console.log(name, options)
    const handleChange = (key: string) => {
      onChange && onChange(key);
    };
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={options.find((obj: any) => obj.key === defaultValue)}
        render={({ field: { onChange, value } }) => (
          <Select
            options={options}
            getOptionLabel={(option: any) =>
              labelIsResource
                ? intl.formatMessage({ id: `${optionPrefix}${option.value}` })
                : option.value
            }
            getOptionValue={(option: any) => option.key}
            isMulti={isMulti}
            value={value}
            onChange={onChange}
            classNamePrefix="select-component"
          />
        )}
      />
      // <Controller
      //   control={control}
      //   defaultValue={defaultValue}
      //   name={name}
      //   render={({ field: { onChange, value, ref } }) => {
      //     return (
      //       <Select
      //         ref={ref}
      //         options={options}
      //         getOptionLabel={(option: any) => option.value}
      //         getOptionValue={(option: any) => option.key}
      //         isMulti={isMulti}
      //         defaultValue={options?.find((c: any) => {
      //           return String(c.key) === String(value);
      //         })}
      //         onChange={(val) => {
      //           onChange(isMulti ? val : val.key);
      //           handleChange(isMulti ? val : val.key);
      //         }}
      //         classNamePrefix="select-component"
      //         placeholder={placeholder}
      //         noOptionsMessage={() =>
      //           intl.formatMessage({ id: "GENERAL.RESULT_NOT_FOUND" })
      //         }
      //         {...props}
      //       />
      //     );
      //   }}
      // />
      // <Controller
      //   control={control}
      //   defaultValue={defaultValue}
      //   name={name}
      //   render={({ field: { onChange: onChangeController, ref } }) => (
      //     <Select
      //       ref={ref}
      //       options={options}
      //       getOptionLabel={(option: any) => option.value}
      //       getOptionValue={(option: any) => option.key}
      //       defaultValue={options?.find(
      //         (c: any) => Number(c.key) === Number(defaultValue)
      //       )}
      //       {...(onChange
      //         ? {
      //             onChange: (val: any) => {
      //               onChangeController(val);
      //               handleChange(val);
      //             },
      //           }
      //         : {
      //             onChange: (val: any) => {
      //               onChangeController(val.key);
      //             },
      //           })}
      //       classNamePrefix="select-component"
      //       placeholder={placeholder}
      //       noOptionsMessage={() =>
      //         intl.formatMessage({ id: "GENERAL.RESULT_NOT_FOUND" })
      //       }
      //       {...(selectedValue
      //         ? {
      //             value: options?.find(
      //               (c: any) => Number(c.key) === Number(selectedValue)
      //             ),
      //           }
      //         : {})}
      //       {...props}
      //     />
      //   )}
      // />
    );
  }
);
