import RoleForm from "Pages/Definitions/Roles/RoleForm";
import { lazy } from "react";

// const isLocal = process.env.REACT_APP_STAGE === 'local';

const DashboardPage = lazy(() => import("Pages/Dashboard/Dashboard"));
const EquipmentsPage = lazy(() => import("Pages/Equipments/Equipments"));
const EquipmentsFormPage = lazy(
  () => import("Pages/Equipments/EquipmentsForm")
);
const UsersPage = lazy(() => import("Pages/Users/Users"));
const MembersPage = lazy(() => import("Pages/Members/Members"));
const MembersFormPage = lazy(() => import("Pages/Members/MemberForm"));
const MemberDetailPage = lazy(() => import("Pages/Members/MemberDetail"));
// const MembersPage = lazy(() => import("Pages/Members/MembersList/Members"));
const PendingMembersPage = lazy(() => import("Pages/Members/PendingMembers"));
const ExercisesPage = lazy(() => import("Pages/Exercises/Exercises"));
const ExerciseFormPage = lazy(() => import("Pages/Exercises/ExerciseForm"));
const WorkoutsPage = lazy(() => import("Pages/Workouts/Workouts"));
const WorkoutFormPage = lazy(() => import("Pages/Workouts/WorkoutForm"));
const WorkoutDetailPage = lazy(() => import("Pages/Workouts/WorkoutDetail"));
const UserDetailPage = lazy(() => import("Pages/Users/Detail/UserDetail"));
const UserDetailEditPage = lazy(
  () => import("Pages/Users/Detail/UserDetailEdit")
);
const SportCenterProfilePage = lazy(
  () => import("Pages/SportCenterProfile/SportCenterProfile")
);
const DefinitionsPage = lazy(() => import("Pages/Definitions/Definitions"));
const SettingsPage = lazy(() => import("Pages/Settings/Settings"));
const RolesPage = lazy(() => import("Pages/Definitions/Roles/Roles"));
const RolesDetailPage = lazy(
  () => import("Pages/Definitions/Roles/RolesDetail")
);

export interface RoutesModel {
  path: string;
  components: JSX.Element;
  label?: string;
  icon?: string;
  fontIcon?: string;
  children?: RoutesChildModel[] | any;
  subMenu?: boolean;
  subMenuList?: any;
}
export interface RoutesChildModel {
  path: string;
  components: JSX.Element;
}

export const SiteRoutes: RoutesModel[] = [
  {
    path: "/dashboard",
    components: <DashboardPage />,
  },
  {
    path: "/members",
    components: <MembersPage />,
  },
  {
    path: "/members/:id",
    components: <MembersFormPage />,
  },
  {
    path: "/members/:id/detail",
    components: <MemberDetailPage />,
  },
  {
    path: "/pending-members",
    components: <PendingMembersPage />,
  },
  {
    path: "/pending-members/:id",
    components: <MembersFormPage />,
  },
  {
    path: "/users",
    components: <UsersPage />,
  },
  {
    path: "/users/:id",
    components: <UserDetailPage />,
  },
  {
    path: "/users/:id/edit",
    components: <UserDetailEditPage />,
  },
  {
    path: "/equipments",
    components: <EquipmentsPage />,
  },
  {
    path: "/equipments/:id",
    components: <EquipmentsFormPage />,
  },
  {
    path: "/exercises",
    components: <ExercisesPage />,
  },
  {
    path: "/exercises/:id",
    components: <ExerciseFormPage />,
  },
  {
    path: "/workouts",
    components: <WorkoutsPage />,
  },
  {
    path: "/workouts/:id/detail",
    components: <WorkoutDetailPage />,
  },
  {
    path: "/workouts/:id",
    components: <WorkoutFormPage />,
  },
  {
    path: "/sportcenterprofile",
    components: <SportCenterProfilePage />,
  },
  {
    path: "/definitions",
    components: <RolesPage />,
  },
  {
    path: "/roles",
    components: <RolesPage />,
  },
  {
    path: "/roles/:id/detail",
    components: <RolesDetailPage />,
  },
  {
    path: "/roles/:id",
    components: <RoleForm />,
  },
];
