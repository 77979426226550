import { ApiURLS } from "Constants/RequestURL";
import { SportCenterApi } from "Services/ApiSportCenter";
import { authError, setMenu } from "Store/features/authSlice";

export const masterApi = SportCenterApi.injectEndpoints({
  endpoints: (build) => ({
    getProfile: build.query<any, void>({
      query() {
        return {
          url: "user-sport-center/63dcf6c9cd93fb9c61cf39eb",
          method: "GET",
        };
      },
    }),
    getMenu: build.mutation({
      query: () => ({
        url: ApiURLS.MENU,
        method: "GET",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log(data?.data)
          dispatch(setMenu(data?.data));
        } catch (error: any) {
          dispatch(authError(error.error));
        }
      },
    }),
    enumList: build.query<any, void>({
      query() {
        return { url: ApiURLS.ENUMS, method: "GET" };
      },
    }),
  }),
});

export const { useGetProfileQuery, useGetMenuMutation, useEnumListQuery } =
  masterApi;
