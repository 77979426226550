import { AsideMenuItem } from "./AsideMenuItem";
import { useIntl } from "react-intl";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { selectMenu } from "Store/features/authSlice";
import { useSelector } from "react-redux";

export function AsideMenuMain() {
  const intl = useIntl();
  const menu = useSelector(selectMenu);
  console.log("00000000000",menu)
  return (
    <>
      {menu ? 
        menu.map((siteRoute: any, index: number) =>
          siteRoute.subMenu.length > 0 ? (
            <AsideMenuItemWithSub
              key={index}
              to={siteRoute.link}
              icon="/media/icons/duotune/arrows/arr001.svg"
              title={intl.formatMessage({ id: siteRoute.caption })}
              fontIcon="bi-app-indicator"
            >
              {siteRoute.subMenu.map((sub: any, i: number) => (
                <AsideMenuItem
                  key={i}
                  to={sub.link}
                  title={intl.formatMessage({ id: sub.caption })}
                  hasBullet={true}
                />
              ))}
            </AsideMenuItemWithSub>
          ) : (
            <AsideMenuItem
              key={index}
              to={siteRoute.link}
              icon="/media/icons/duotune/arrows/arr001.svg"
              title={intl.formatMessage({ id: siteRoute.caption })}
              fontIcon="bi-app-indicator"
            />
          )
        ) : <div></div>
      }

    </>
  );
}
