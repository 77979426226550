import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { MasterInit } from "Layout/MasterInit";
import { LayoutProvider, LayoutSplashScreen } from "Layout/core";
import { I18nProvider } from "Helpers/i18n/i18nProvider";
import { MetronicI18nProvider } from "Helpers/i18n/Metronici18n";
import { ThemeModeProvider } from "Layout/core/theme-mode/ThemeModeProvider";

function App() {
  return (
    <MetronicI18nProvider>
      <ThemeModeProvider>
        <Suspense fallback={<LayoutSplashScreen />}>
          <I18nProvider>
            <LayoutProvider>
              {/* <AuthInit> */}
              <MasterInit />
              <Outlet />
              {/* </AuthInit> */}
            </LayoutProvider>
          </I18nProvider>
        </Suspense>
      </ThemeModeProvider>
    </MetronicI18nProvider>
  );
}

export default App;
