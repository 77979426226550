import Cookies from "js-cookie";

export const TokenHelper = {
  Token: () => {
    return Cookies.get("SPORT_CENTER_APP.token");
  },
  Check: () => {
    let _token = TokenHelper.Token();
    if (typeof _token === "undefined") {
      return false;
    } else if (!_token) {
      return false;
    } else if (_token.length < 20) {
      return false;
    } else if (
      typeof JSON.parse(Cookies.get("SPORT_CENTER_APP.token")) === "undefined"
    ) {
      return false;
    } else {
      return true;
    }
  },
  Get: {
    AccessToken: () => {
      if (TokenHelper.Check()) {
        return JSON.parse(TokenHelper.Token())["accessToken"];
      } else {
        return "Bearer -";
      }
    },
    RefreshToken: () => {
      if (TokenHelper.Check()) {
        return JSON.parse(TokenHelper.Token())["refreshToken"];
      } else {
        return "-";
      }
    },
  },
  Set: (token) => {
    return Cookies.set("SPORT_CENTER_APP.token", JSON.stringify(token));
  },
  Delete: () => {
    return Cookies.remove("SPORT_CENTER_APP.token", {
      path: "/",
      domain: "localhost", //TODO: environment'e göredeğişecek
    });
  },
};
