import { useEffect, useState } from "react";
import { useEnumListQuery } from "./MasterApi";

export function MasterInit() {
  const [enumsCheck, setEnumsCheck] = useState(false);
  const { data: enums } = useEnumListQuery(undefined, {
    skip: !enumsCheck,
  });

  useEffect(() => {
    let enumsStorage = localStorage.getItem("SPORT_CENTER_APP.enums");
    if (!enumsStorage) {
      setEnumsCheck(true);
    }
    if (enums) {
      localStorage.setItem(
        "SPORT_CENTER_APP.enums",
        JSON.stringify(enums?.data)
      );
    }
  }, [enums]);

  return <></>;
}
