export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0];
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname);
  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }

  if (current.indexOf(url) > -1) {
    return true;
  }

  return false;
}


export const getPermission = (
  location: any,
  menuItems: any,
  permissionsItems: any
) => {
  let guid = '';
  let permissions = '';

  menuItems.forEach((item: any) => {
    if (item.link === location.pathname) {
      const objCopy = { ...item }; // objenin bir kopyasını oluştur
      guid = objCopy.guid;
    } else if (item.subMenu && item.subMenu.length > 0) {
      item.subMenu.forEach((subItem: any) => {
        if (subItem.link === location.pathname) {
          const objCopy = { ...subItem }; // objenin bir kopyasını oluştur
          guid = objCopy.guid;
        }
      });
    }
  });

  permissionsItems.forEach((item: any) => {
    if (item.routeId === guid) {
      permissions = item.permissions;
    }
  });

  return permissions;
}