// /* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { TokenHelper } from "Helpers/Token.helper";
import { toast } from "react-toastify";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    refreshToken: null,
    user: null,
    isAuthenticated: false,
    member: null,
    userSportCenter: null,
    role: null,
    menu: null,
  },
  reducers: {
    setLogin: (state, action) => {
      const { accessToken, refreshToken } = action.payload;
      state.token = accessToken;
      state.refreshToken = refreshToken;
      TokenHelper.Set(action.payload);
    },
    setUser: (state, action) => {
      const user = action.payload;
      state.token = state.token || TokenHelper.Get.AccessToken();
      state.refreshToken = state.refreshToken || TokenHelper.Get.RefreshToken();
      state.user = user;
      state.isAuthenticated = true;
    },
    logOut: (state) => {
      state.token = null;
      state.user = null;
      state.isAuthenticated = false;
    },
    setUserSportCenter: (state, action) => {
      state.userSportCenter = action.payload;
    },
    setUserMember: (state, action) => {
      state.member = action.payload;
    },
    setUserRole: (state, action) => {
      state.role = action.payload;
    },
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
    authError: (state, { payload }) => {
      state.token = null;
      state.user = null;
      state.isAuthenticated = false;
      toast.error("Auth Error", {
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    },
  },
});

export const {
  setLogin,
  setUser,
  logOut,
  authError,
  setUserSportCenter,
  setUserMember,
  setUserRole,
  setMenu,
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentToken = (state: any) => state.auth.token;
export const selectIsAuthenticated = (state: any) => state.auth.isAuthenticated;
export const selectAuthenticatedUser = (state: any) => state.auth.user;
export const selectUserSportCenter = (state: any) => state.auth.userSportCenter;
export const selectUserRole = (state: any) => state.auth.role;
export const selectMenu = (state: any) => state.auth.menu;
