import { Outlet } from "react-router-dom"
import { AsideDefault } from "./components/aside/AsideDefault"
import { Content } from "./components/Content"
import { HeaderWrapper } from "./components/header/HeaderWrapper"
import { ScrollTop } from "./components/ScrollTop"
import { PageDataProvider } from "./core/PageData"
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "Store/features/authSlice";
import { useLayout } from "./core"
import { useEffect, useRef } from "react"
import { ToggleComponent } from "./components/_ToggleComponent"
import { MenuComponent } from "./components/MenuComponent";
import { DrawerComponent } from "./components/_DrawerComponent";
import { ScrollComponent } from "./components/_ScrollComponent";
import { ScrollTopComponent } from "./components/_ScrollTopComponent";
import { StickyComponent } from "./components/_StickyComponent";
import { SwapperComponent } from "./components/_SwapperComponent";
import { Tab } from "bootstrap";

const MasterLayout = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { config } = useLayout();
  const isFirstRun = useRef(true);
  const pluginsInitialization = () => {
    isFirstRun.current = false;
    setTimeout(() => {
      ToggleComponent.bootstrap();
      ScrollTopComponent.bootstrap();
      DrawerComponent.bootstrap();
      StickyComponent.bootstrap();
      MenuComponent.bootstrap();
      ScrollComponent.bootstrap();
      SwapperComponent.bootstrap();
      document.querySelectorAll('[data-bs-toggle="tab"]').forEach((tab) => {
        Tab.getOrCreateInstance(tab);
      });
    }, 500);
  };

  useEffect(() => {
    if (isFirstRun.current && isAuthenticated) {
      isFirstRun.current = false;
      pluginsInitialization();
    }
    
  }, [config, isAuthenticated]);

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root'>
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault />
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper />

            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <Content>
                <Outlet />
              </Content>
            </div>
            {/* <Footer /> */}
          </div>
        </div>
      </div>
      <ScrollTop /> 
    </PageDataProvider>
  )
}

export {MasterLayout}
